import React, { ReactNode } from 'react'
import Link from 'next/link'
import Head from 'next/head'
import { useAuth } from '../hooks/useAuth'
import { Button, Dropdown } from '@nextui-org/react'
import { useBreakpoints } from '../hooks/useBreakpoints'
import { useRouter } from 'next/router'

type Props = {
  children?: ReactNode
  title?: string
}

const Layout = ({ children, title = 'This is the default title' }: Props) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Supplier for customized marketing and promotional goods"/>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <link rel="icon" type="image/png" href="/logos/penusa-logo-mobile.png" />
      </Head>
      <header>
        <nav>
          <picture>
            {/* <source media="(min-width:768px)" srcSet="/logos/penusa-logo.webp" /> */}
            <img src="/logos/penusa-logo.webp" alt="penusa icon logo" className="logo" />
          </picture>
          <NavLinks />
        </nav>
        <p className="covid"><b>COVID-19:</b> Our office is open and operating as normal, following COVID-19 protocols</p>
      </header>
      {children}
      <section>
        <div className="container">
          <div className="row cta">
            <div className="col-12">
              <h3>Can't find what you're looking for? <strong>We can still help!</strong></h3>
            </div>
            <div className="col-12 col-md-8">
              <p>Sometimes our website is not up-to-date. Sometimes our stock is replenishing. If you can't find what you want, send us a quick e-mail and we will see what we can do for you.</p>
            </div>
            <div className="col-12 col-md">
              <div className="link-container">
                <Link href="/contact" className="secondary-button">Send Us A Message</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <footer>
        <div className="row">
          <div className="col-sm">
            <h4>Contact Us</h4>
            <p className='contact-info'>
              <span className="material-icons">phone</span>
              <a href='tel:1-877-736-8721'>1-877-736-8721</a>
            </p>
            <p className='contact-info'>
              <span className="material-icons">mail</span>
              <a href='mailto:services@penusa.com'>services@penusa.com</a>
            </p>
            <p className='contact-info'>
              <span className="material-icons">location_on</span>
              <a href='https://goo.gl/maps/xxtm3Bk87CxQL6DS6'>
                200 Cochrane Drive Unit 7, Markham,&nbsp;ON, L3R&nbsp;8E8
              </a>
            </p>
          </div>
          <div className="col-sm">
            <h4>Hours of Operation</h4>
            <p className='contact-info'>
              <span className="material-icons">schedule</span>
              Monday - Friday: 9:30am - 5:00pm</p>
          </div>
          <div className="col-12">
            <p className="small-text">Penusa 2022</p>
          </div>
        </div>
      </footer>
    </div>
  )
}

function NavLinks() {
  const { status, logout } = useAuth();
  const { sm } = useBreakpoints();
  const router = useRouter();

  return sm ? (
    <Dropdown>
      <Dropdown.Trigger>
        <span className="material-icons">menu</span>
      </Dropdown.Trigger>
      <Dropdown.Menu aria-label="Static Actions" onAction={key => {
        switch (key) {
          case "home":
            router.push("/");
            break;
          case "shop":
            router.push("/shop");
            break;
          case "contact":
            router.push("/contact");
            break;
          case "faq":
            router.push("/faq");
            break;
          case "auth":
            if (status === "logged-out") {
              router.push("/login");
            } else {
              logout();
            }
            break;
          default:
            throw new Error("Misisng case in nav url actions");
        }
      }}>
        <Dropdown.Item key="home">
          Home
        </Dropdown.Item>
        <Dropdown.Item key="shop">
          Shop
        </Dropdown.Item>
        <Dropdown.Item key="contact">
          Contact
        </Dropdown.Item>
        <Dropdown.Item key="faq">
          FAQ
        </Dropdown.Item>
        {/* <Dropdown.Item key="auth">
          {status !== "logged-in" ? "Login" : "Logout"}
        </Dropdown.Item> */}
      </Dropdown.Menu>
    </Dropdown >
  ) : (
    <span className="links">
      <Link href="/">
        <a>Home</a>
      </Link>
      <Link href="/shop">
        <a>Shop</a>
      </Link>
      <Link href="/contact">
        <a>Contact</a>
      </Link>
      <Link href="/faq">
        <a>FAQ</a>
      </Link>
      {/* {status !== "logged-in" ? <Link href="/login">
        <a>Login</a>
      </Link> : <Button ghost onPress={logout}>Log out</Button>} */}
    </span>
  )
}

export default Layout
