import Link from 'next/link';
import Layout from '../components/Layout';
import { useRouter } from 'next/router';
import { Button, Input } from '@nextui-org/react';
import { useState } from 'react';
import Carousel from "react-bootstrap/Carousel";
import { GetStaticProps } from 'next';
import { getHomePageCategories, getPromotions } from '../server/db';
import { HomePageCategory, Promotion } from '../interfaces';

type Props = {
  promotions: Promotion[],
  homePageCategories: HomePageCategory[],
}

const IndexPage = ({ promotions, homePageCategories }: Props) => {
  const { push } = useRouter();
  const [searchText, setSearchText] = useState("");
  const onSearch = () => {
    push({ pathname: "/shop", query: { search: searchText } })
  }

  return (<Layout title="Penusa">
    <div className="jumbotron">
      <div className="jumbotron-content">
        <h1 className="title">Re-writing the Standard</h1>
        <p className="subtitle">Find your promotional needs here</p>
        <Input className="searchbar"
          width="100%"
          size="md"
          labelLeft="Search"
          type="search"
          value={searchText}
          placeholder="Type full words such as pens, wood, blue hat, etc."
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch();
            }
          }}
          contentRight={
            <Button auto light icon={<span className="material-icons" id="search-icon">search</span>} onPress={onSearch} />
          } />
      </div>
    </div>
    <div className="container">
      <div className="row" id="promo">
        <div className="col-12">
          <Carousel prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
            nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
          >
            {promotions.map((p, index) => (
              <Carousel.Item key={index}>
                <div className="promo-item blue">
                  <div className="promo-content">
                    <h2>{p.description}</h2>
                    <Link href={p.buttonRelativeUrl}>
                      <a className="orange">{p.buttonText}</a>
                    </Link>
                  </div>
                  <div className="promo-image" style={{backgroundImage: `url("/promo/${p.imagePath}")`}}>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="row" id="categories">
        <div className="col-12">
          <h2>We can customize almost everything</h2>
        </div>
        {homePageCategories.map(c => (
          <div key={c.name} className="col-md-4">
            <Link href={`/shop?categories=${c.category}`}>
              <a className="category">
                <img src={`/categories/${c.imagePath}`} alt="promotional image" />
                <span className="category-name">{c.name}</span>
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </Layout >
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const promotions = await getPromotions();
  const homePageCategories = await getHomePageCategories();

  return { props: { promotions, homePageCategories } };
}

export default IndexPage
